<div class="banner-container" #onTop>
  <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation02.jpg"
      alt="Toutes nos prestations soins & massages">
  </div>
  <div class="banner-head">
    <div class="banner-head-padding banner-head-margin">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12"> <span class="heading-meta">Zalona</span>
            <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft">Nos prestations</h2>
            <!-- <h6 *ngIf="selectedParent.length > 0">{{findFamilly().libelle}}</h6> -->

            <ol *ngIf="selectedParent.length > 0" class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="" href="null" (click)="clickFamilly(null)"
                  style="position: relative; width: 25px">
                  <mat-icon aria-hidden="false" class="icon" style="position: absolute; top: -18px; left: 0px">home
                  </mat-icon>
                </a></li>
              <li class="breadcrumb-item" [ngClass]="{'active': list.id === selectedParent[selectedParent.length - 1]}"
                *ngFor="let list of breadcrumbFamillies()"><a [routerLink]=""
                  (click)="clickFamilly(list)">{{list.name}}</a></li>
            </ol>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="portfolio-section portfolio pt-0 pb-60" *ngIf="templateFamillies.length > 0">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4" *ngFor="let familly of templateFamillies">
        <div class="item animate-box" data-animate-effect="fadeInLeft">


          <div class="portfolio-img" (click)="clickFamilly(familly)">
            <img *ngIf="familly.image" [src]="familly.image.url.default" [alt]="familly.name"
              style="height: 200px; object-fit: cover;">
          </div>

          <div class="content" (click)="clickFamilly(familly)">
            <h5>{{familly.name}} <i class="ti-arrow-right"></i></h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="price-section pb-60 price bg-pink" *ngIf="templatePrestations.length > 0">
  <div class="container-fluid">

    <div class="row">

      <!--<div class="col-md-12 zoneSearch"><input name="search" type="text"
          placeholder="Rechercher une prestation dans cette catégorie" class="inputSearch" [(ngModel)]="search"></div>-->


      <div class="col-md-12 zalona-flex-center">
        <div style="display: flex; justify-content: center; flex-wrap: wrap;">
          <div class="item animate-box" data-animate-effect="fadeInLeft"
            *ngFor="let prestation of (templatePrestations  | searchFilter:search) as prestations">

            <app-services-details [prestation]="prestation" [(detailsIdUrl)]="detailsIdUrl"></app-services-details>

          </div>

          <div *ngIf="(templatePrestations | searchFilter:search).length < 1" class="notFound">
            Aucune prestation n'a été trouvé pour votre recherche
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
