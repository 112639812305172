<div [@inOut]="dispatchBack" class="bgDispatch zalona-flex-center" (click)="disabledPopup($event)">
  <div [@inOutChild]="dispatchBack" class="zalona-flex-center" style="width: 100%; height: 100%">

    <h5 class="iconClose"><a (click)="disabledPopup($event)"><i class="ti-close"></i></a></h5>

    <div>

      <div class="row" *ngIf="selectPrestation !== null">
        <div class="col-md-12" style="color: white; font-size: 18px; font-weight: 400; text-align: center"
          [innerHTML]="selectPrestation.name">
        </div>
        <div class="col-md-12" style="margin-top: 20px; margin-bottom: 20px">
          <h6 style="text-align: center; color: white; margin-bottom: 0px; font-weight: 200; font-size: 35px">
            {{selectPrestation.sell_price}}€</h6>
        </div>


      </div>


      <div class="row" style="margin-bottom: 20px;">
        <div class="col-md-6 zalona-flex-center">

          <div class="zoneBtn zalona-flex-center notClose"
            *ngIf="selectPrestation !== null && selectPrestation.online == 1"
            (click)="reservation($event, selectPrestation)">
            <div class="row notClose">
              <div class="col-md-12 notClose" style="text-align: center; font-size: 20px">
                <mat-icon class="iconRef notClose">calendar_today</mat-icon>
              </div>
              <div class="col-md-12 notClose" style="text-align: center; font-size: 20px">
                Réserver
              </div>
            </div>
          </div>

          <div class="zoneBtn zalona-flex-center notClose"
            *ngIf="selectPrestation !== null && selectPrestation.online == 0" (click)="contactUs(selectPrestation)">
            <div class="row notClose">
              <div class="col-md-12 notClose" style="text-align: center; font-size: 20px">
                <mat-icon class="iconRef notClose">calendar_today</mat-icon>
              </div>
              <div class="col-md-12 notClose" style="text-align: center; font-size: 20px">
                Réserver
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-6 zalona-flex-center">

          <div class="zoneBtn zalona-flex-center notClose" (click)="offer(selectPrestation)">
            <div class="row notClose">
              <div class="col-md-12 notClose" style="text-align: center; font-size: 20px">
                <mat-icon class="iconRef notClose">card_giftcard</mat-icon>
              </div>
              <div class="col-md-12 notClose" style="text-align: center; font-size: 20px">
                Offrir
              </div>
            </div>
          </div>


        </div>
      </div>
      <div class="row" *ngIf="selectPrestation !== null">
        <div class="col-md-12" style="color: white; font-size: 12px; font-weight: bold; text-align: center">
          <u>DESCRIPTION</u>
        </div>
        <div class="col-md-12" style="color: white; font-size: 15px; font-weight: 200; text-align: center"
          [innerHTML]="selectPrestation.description">
        </div>

      </div>

    </div>

  </div>
</div>

<div class="banner-container" #onTop>
  <div class="banner-img"> <img class="banner-img-width" src="../../assets/images/banner-prestation02.jpg"
      alt="Toutes nos prestations soins & massages">
  </div>
  <div class="banner-head">
    <div class="banner-head-padding banner-head-margin">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12"> <span class="heading-meta">Zalona</span>
            <h2 class="pwe-heading animate-box" data-animate-effect="fadeInLeft">Nos offres du moment</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="portfolio-section portfolio pt-0 pb-60" *ngIf="templateFamillies.length > 0">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4" *ngFor="let familly of templateFamillies">
        <div class="item animate-box" data-animate-effect="fadeInLeft">


          <div class="portfolio-img" (click)="clickFamilly(familly)">
            <img *ngIf="familly.image" src="{{familly.image.url.default}}" alt=""
              style="height: 200px; object-fit: cover;">
          </div>

          <div class="content" (click)="clickFamilly(familly)" style="width: 400px">
            <h5>{{familly.name}} <i class="ti-arrow-right"></i></h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="portfolio pt-90 pb-60 bg-pink" *ngIf="templatePrestations.length > 0">
  <div class="container-fluid">

    <div class="row">

      <div class="col-md-6 zalona-flex-center"
        *ngFor="let prestation of (templatePrestations  | searchFilter:search) as prestations">
        <div class="item animate-box fadeInLeft animated" data-animate-effect="fadeInLeft">
          <div class="portfolio-img">
            <a href="" [routerLink]="" (click)="dispatch(prestation)"><img src="{{prestation.image.url.default}}"
                style="max-width: 400px; object-fit: cover;" alt=""></a>
          </div>
          <div class="content" style="max-width: 400px;">
            <h5><a href="" [routerLink]="" (click)="dispatch(prestation)">{{prestation.name}}</a></h5>
            <h6 style="text-align: center; color: white; margin-bottom: 0px; font-weight: 200; font-size: 22px">
              {{prestation.sell_price}}€</h6>
          </div>
        </div>

      </div>


    </div>
  </div>
</div>
